import { PanelPlugin } from '@grafana/data';
import { MediaLibraryOptions } from './types';
import { MediaLibraryPanel } from './MediaLibraryPanel';

export const plugin = new PanelPlugin<MediaLibraryOptions>(MediaLibraryPanel).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'mediaQueryName',
      name: 'Media Urls (QueryName)',
      description: 'Name der Query',
      defaultValue: '',
    })
    .addTextInput({
      path: 'titleColumn',
      name: 'Titel des Videos oder Pdfs (Spaltenname)',
      description: 'Name der Spalte',
    })
    .addTextInput({
      path: 'urlColumn',
      name: 'Video oder PDF Url (Spaltenname)',
      description: 'Name der Spalte',
    });
});
