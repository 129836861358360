import React from 'react';
import { PanelProps } from '@grafana/data';
import { HorizontalGroup, VerticalGroup, Label } from '@grafana/ui';
import { hackToString, getValuesFromQuery } from 'uef-common';

import { MediaLibraryOptions } from './types';

const pdfIcon = require('./img/pdf.png');

interface Props extends PanelProps<MediaLibraryOptions> {}

const cellWidth = 400;

export const MediaLibraryPanel: React.FC<Props> = ({ options, data, width, height }) => {
  const titles = getValuesFromQuery(data, options.mediaQueryName, options.titleColumn);
  const urls = getValuesFromQuery(data, options.mediaQueryName, options.urlColumn);

  console.log('received titles', titles);
  console.log('received urls', urls);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'auto', padding: '30px' }}>
      <HorizontalGroup wrap spacing="lg" justify="space-between">
        {urls.map((url, index) => {
          const title = titles[index];
          // TODO is this safe?
          let urlString: string = hackToString(url);
          let titleString: string = hackToString(title);
          const isPdf = urlString.endsWith('.pdf');
          return (
            <VerticalGroup key={index}>
              <Label>{titleString}</Label>
              {isPdf ? (
                <a href={urlString} target="_blank" rel="noreferrer">
                  <img src={pdfIcon} height="200px" width={cellWidth}></img>
                </a>
              ) : (
                <iframe
                  width={cellWidth}
                  height="200px"
                  src={urlString}
                  frameBorder="0"
                  title={titleString}
                  allowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              )}
            </VerticalGroup>
          );
        })}
      </HorizontalGroup>
    </div>
  );
};
